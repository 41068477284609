import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

const useStyles = makeStyles((theme) => ({
  iframe: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    height: '80vh',
    width: '50vw',

    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
  },
}))

const IframePopup = ({ src, isOpen, onClose }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} onClose={onClose}>
      <iframe
        src={src}
        className={classes.iframe}
      />
    </Modal>
  )
}
export default IframePopup
