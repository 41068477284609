import { hexToRgba } from '../../utils/functions'

export const GRAY_SCALE_0 = '#FFFFFF'
export const GRAY_SCALE_1 = '#f5f5f5'
export const GRAY_SCALE_2 = '#eeeeee'
export const GRAY_SCALE_3 = '#e0e0e0'
export const GRAY_SCALE_4 = '#bdbdbd'
export const GRAY_SCALE_5 = '#9e9e9e'
export const GRAY_SCALE_6 = '#757575'
export const GRAY_SCALE_7 = '#616161'
export const GRAY_SCALE_8 = '#424242'
export const GRAY_SCALE_9 = '#212121'
export const BLUE_BASE = '#092A48'
export const BLUE_LIGHT = '#ECF2F5'
export const WHITE = '#FFFFFF'
export const GOLDEN_BASE = '#D8B45A'
export const DARK_1 = '#000000'
export const PRIMARY = '#000000de'
export const SECONDARY = '#0000008a'
export const DISABLED_HINT = '#00000061'
export const HOVER = '#0000000a'
export const SELECTED = '#00000014'
export const DISABLED_BG_FOCUS = '#0000001f'
export const DISABLED = '#00000042'
export const BORDER = '#0000003b'
export const BACKDROP = '#00000080'
export const SNACKBAR = '#323232'
export const ACTIVE_RATING = '#ffb400'
export const ACTIVE_LOVING = '#FF6D75'
export const KNOB_INACTIVEKNOB = '#fafafa'
export const TEXT_DARK = '#0D3C61'
export const MAIN = '#2196f3'
export const DARK = '#0b79d0'
export const LIGHT = '#64b6f7'
export const LIGHT_BG = '#E9F5FE'
export const SUCCESS_TEXT_DARK = '#1E4620'
export const SUCCESS_MAIN = '#4caf50'
export const SUCCESS_DARK = '#3b873e'
export const SUCCESS_LIGHT = '#7bc67e'
export const SUCCESS_LIGHT_BG = '#EDF7ED'
export const ERROR_TEXT_DARK = '#621B16'
export const ERROR_MAIN = '#f44336'
export const ERROR_DARK = '#e31b0c'
export const ERROR_LIGHT = '#f88078'
export const ERROR_LIGHT_BG = '#FEECEB'
export const WARNING_TEXT_DARK = '#663D00'
export const WARNING_MAIN = '#ff9800'
export const WARNING_DARK = '#c77700'
export const WARNING_LIGHT = '#ffb547'
export const WARNING_LIGHT_BG = '#FFF5E5'
export const GREY_A100 = '#d5d5d5'
export const GREY_A200 = '#aaaaaa'
export const GREY_A700 = '#303030'
export const SECONDARY_BLUE = '#366590'
export const TERTIARY_BLUE = '#2094CF'
export const GREEN = '#01ab8e'
export const PINK = '#d91883'
export const NEW_YELLOW = '#ffb81c'
export const BLUE_BASE_OPACITY_40 = hexToRgba(BLUE_BASE, 0.4)
export const ORANGE = '#ff6900'
export const GRAY = '#dde5ed'

export default {
  GRAY_SCALE_0,
  GRAY_SCALE_1,
  GRAY_SCALE_2,
  GRAY_SCALE_3,
  GRAY_SCALE_4,
  GRAY_SCALE_5,
  GRAY_SCALE_6,
  GRAY_SCALE_7,
  GRAY_SCALE_8,
  GRAY_SCALE_9,
  BLUE_BASE,
  BLUE_LIGHT,
  WHITE,
  GOLDEN_BASE,
  DARK_1,
  PRIMARY,
  SECONDARY,
  DISABLED_HINT,
  HOVER,
  SELECTED,
  DISABLED_BG_FOCUS,
  DISABLED,
  BORDER,
  BACKDROP,
  SNACKBAR,
  ACTIVE_RATING,
  ACTIVE_LOVING,
  KNOB_INACTIVEKNOB,
  TEXT_DARK,
  MAIN,
  DARK,
  LIGHT,
  LIGHT_BG,
  SUCCESS_MAIN,
  SUCCESS_DARK,
  SUCCESS_LIGHT,
  SUCCESS_LIGHT_BG,
  ERROR_TEXT_DARK,
  ERROR_MAIN,
  ERROR_DARK,
  ERROR_LIGHT,
  ERROR_LIGHT_BG,
  WARNING_TEXT_DARK,
  WARNING_MAIN,
  WARNING_DARK,
  WARNING_LIGHT,
  WARNING_LIGHT_BG,
  GREY_A100,
  GREY_A200,
  GREY_A700,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  GREEN,
  PINK,
  NEW_YELLOW,
  BLUE_BASE_OPACITY_40,
  ORANGE,
  GRAY,
}
