import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { USER_EXPERIENCES } from '../constants'

export const USER_KEY = 'USER_INFO'

export const AuthContext = React.createContext({})

export const useAuthContext = () => {
  return React.useContext(AuthContext)
}

const defaultUser = {}

function parseUserObject(userToParse, leadToParse, contactToParse) {
  const {
    Contact_Owner_Email__c,
    Contact_Owner_Full_Name__c,
    Contact_Owner_Phone__c,
    Contact_Owner_Photo_Link__c,
    Contact_Owner_Calendar__c,
    ContactId,
    Email,
    UserType,
    IsActive,
  } = userToParse

  return {
    name: leadToParse?.Name?.value ?? contactToParse?.Name?.value,
    Id: leadToParse?.Id ?? contactToParse?.Id,
    contactEmail: Contact_Owner_Email__c?.value,
    contactFullName: Contact_Owner_Full_Name__c?.value,
    contactPhone: Contact_Owner_Phone__c?.value,
    contactPhotoLink: Contact_Owner_Photo_Link__c?.value?.replaceAll(
      '&amp;',
      '&'
    ),
    contactOwnerCalendarLink: Contact_Owner_Calendar__c?.value,
    contactId: ContactId?.value,
    ownerEmail: Email?.value,
    ownerUserType: UserType?.value,
    ownerIsActive: IsActive?.value,
    isContact: Boolean(contactToParse),
  }
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(defaultUser)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userExperience, setUserExperience] = useState(
    USER_EXPERIENCES.UNATTESTED
  )
  const [cookies] = useCookies(['acceptedAttestation', 'harborCookie'])
  const { harborCookie } = cookies

  const tryLogin = () => {
    const userData = JSON.parse(window.sessionStorage.getItem('USER_DATA'))

    if (userData) {
      setUser(userData)
    } else if (harborCookie) {
      const [sfContactId, sfOwnerId] = harborCookie?.split('|').slice(3) ?? []

      if (
        !!sfContactId &&
        !!sfOwnerId &&
        sfContactId !== 'null' &&
        sfOwnerId !== 'null'
      ) {
        fetch('/api/get-user')
          .then((res) => res.json())
          .then((res) => {
            const userObjectResponse =
              res?.user?.data?.uiapi?.query?.User?.edges?.[0]?.node
            const userLeadResponse =
              res?.lead?.data?.uiapi?.query?.Lead?.edges?.[0]?.node
            const userContactResponse =
              res?.contact?.data?.uiapi?.query?.Contact?.edges?.[0]?.node
            if (
              userObjectResponse &&
              (userLeadResponse || userContactResponse)
            ) {
              const parsedUserObject = parseUserObject(
                userObjectResponse,
                userLeadResponse,
                userContactResponse
              )
              setUser(parsedUserObject)
              window.sessionStorage.setItem(
                'USER_DATA',
                JSON.stringify(parsedUserObject)
              )
            }
          })
          .catch(() => {
            window.sessionStorage.setItem('USER_DATA', JSON.stringify({}))
          })
      }
    }

    setUserLoaded(true)
  }

  useEffect(tryLogin, [setUser])

  const isLoggedIn = useMemo(
    () =>
      Boolean(user?.contactId) &&
      user.ownerIsActive &&
      user.ownerUserType === 'Standard' &&
      user.ownerEmail.includes('harborcapital.com'),
    [user]
  )

  useEffect(() => {
    let newUserExperience = USER_EXPERIENCES.UNATTESTED

    if (cookies.acceptedAttestation === 'true') {
      newUserExperience = USER_EXPERIENCES.INSTITUTIONAL_ONLY
    } else if (cookies.acceptedAttestation === 'false') {
      newUserExperience = USER_EXPERIENCES.RETAIL_ONLY
    }

    setUserExperience(newUserExperience)
  }, [isLoggedIn, cookies.acceptedAttestation])

  const value = {
    state: {
      user,
      isLoggedIn,
      userExperience,
      userLoaded,
    },
    actions: {
      tryLogin,
      setUser,
      setUserExperience,
    },
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
