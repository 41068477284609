import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Location } from '@reach/router'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'
import IframePopup from '../components/IframePopup'
import { AuthContext } from './context/AuthContext'
import { ONE_HOUR_COOKIE_EXPIRE, USER_EXPERIENCES } from './constants'
import { AttestationModal } from '../components/attestationModal'

function useSendUserExperienceGtm({ allRoutes, pathname, isInsight }) {
  useEffect(() => {
    const isWindowDefined = typeof window !== 'undefined'

    if (isWindowDefined && isInsight) {
      const onlyInsightsRoutes = [
        ...(allRoutes?.allContentstackInsightArticle?.nodes ?? []),
        ...(allRoutes?.allContentstackInsightWebinar?.nodes ?? []),
      ]

      const currentInsight = onlyInsightsRoutes.find(({ url }) =>
        pathname.includes(url)
      )

      const currentInsightExperience =
        currentInsight?.user_experience?.experience

      if (currentInsightExperience) {
        window.dataLayer.push({
          userExperience: currentInsightExperience,
        })
      }
    }
  }, [isInsight, pathname])
}

const skipLoginPaths = ['/contact-us/institutional']

const GatedContentHandler = ({ children, router }) => {
  const [openIframe, setIsOpenIframe] = useState(false)
  const allRoutes = useStaticQuery(graphql`
    query {
      allContentstackContactUsLoggedIn(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightArticle {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightWebinar {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackLandingPage(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
    }
  `)

  const [cookies] = useCookies(['harborCookie'])
  const { harborCookie } = cookies

  const authCtx = useContext(AuthContext)

  let { pathname } = router.location

  const isInsight = useMemo(() => pathname.startsWith('/insights/'), [pathname])

  useSendUserExperienceGtm({ allRoutes, pathname })

  const privatePages = Object.keys(allRoutes)
    .flatMap((key) => {
      return allRoutes[key].nodes
    })
    .filter((route) => {
      return (
        route.user_experience.experience === USER_EXPERIENCES.INSTITUTIONAL_ONLY
      )
    })
    .map((route) => route.url)

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.substr(0, pathname.length - 1)
  }

  const isPrivatePage = privatePages.indexOf(pathname) >= 0
  const isSignupViewed = Cookies.get('signupViewed') === 'true'

  useEffect(() => {
    if (isPrivatePage && !isSignupViewed) {
      const [visitorId, prospectId, viewCount, sfContactId, sfOwnerId] =
        harborCookie?.split('|') ?? []

      if (
        !prospectId ||
        !sfContactId ||
        !sfOwnerId ||
        prospectId === 'null' ||
        sfContactId === 'null' ||
        sfOwnerId === 'null'
      ) {
        if (!skipLoginPaths.includes(pathname)) {
          fetch('/api/harbor-cookie')
            .then((res) => res.json())
            .then((res) => {
              if (!res) {
                Cookies.set('signupViewed', 'true', {
                  expires: ONE_HOUR_COOKIE_EXPIRE,
                })
                window.dataLayer.push({
                  signUpPopupViewed: 'User reached 10 articles viewed',
                })
                setIsOpenIframe(true)
              } else {
                authCtx.actions.tryLogin()
              }
            })
        }
      }
    }
  }, [pathname, setIsOpenIframe])

  const handleCloseIframe = () => {
    setIsOpenIframe(false)
  }

  const shouldShowAttestationModal =
    !isInsight &&
    isPrivatePage &&
    !authCtx?.state?.isLoggedIn &&
    authCtx?.state.userExperience !== USER_EXPERIENCES.INSTITUTIONAL_ONLY

  const handleAttestationClose = () => {
    navigate('/')
  }

  return (
    <>
      {openIframe && (
        <IframePopup
          src="https://forms.harborcapital.com/f/signup"
          isOpen={openIframe}
          onClose={handleCloseIframe}
        />
      )}
      <AttestationModal
        open={shouldShowAttestationModal}
        onCloseCancel={handleAttestationClose}
      />
      {children}
    </>
  )
}

const withLocation = ({ children }) => {
  return (
    <Location>
      {(location) => (
        <GatedContentHandler children={children} router={location} />
      )}
    </Location>
  )
}
export default React.memo(withLocation)
